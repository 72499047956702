import React from 'react';
import {DatePicker, Form, InputNumber, Input, Skeleton, Switch, Collapse} from 'antd';
import moment, { Moment } from 'moment';
import { ChevronLeft, ChevronsDown, ChevronsUp, X } from 'react-feather';

import {
	BalanceAttribute,
	BalanceAttributeType,
	payloads,
	EntityHolder,
	EntityPerBalance,
	TransactionType,
	Tree,
	TreeListEntry,
	NodesState, Balance, EntityPerTree, BalanceType
} from '../../models';
import {config, errors, localization, slugs} from '../../settings';
import { Button } from '../../components';

import BalanceAttributeView from './BalanceAttributeView';
import NodeInTree from './NodeInTree';
import {FormInstance} from "antd/lib/form";
import RecordsList from "../TreeProperties/content/RecordsList";

const {TextArea} = Input

export interface SourceEndpoint{
	treeId: number,
	node: Tree,
	balanceId: number,
}

export interface TargetEndpoint{
	treeId: number | null,
	node: Tree | null,
	balanceId: number | null,
}

type BalanceTypeSlug = 'natural' | 'finance' | 'calculate'

interface TransactionWizardProps{
	className?: string,
	trees: TreeListEntry[] | null,
	isTreesLoading: boolean,
	source:  SourceEndpoint,
	target?: TargetEndpoint,
	isExpanded: boolean,
	triggerExpanded: () => void,
	isExpanderShown: boolean,
	nodesInfo: NodesState,
	node?: Tree | null,

	balanceAttributes: EntityPerBalance<BalanceAttribute>,
	balanceAttributeTypes: EntityHolder<BalanceAttributeType>,
	balanceTypes?: BalanceType[] | null,
	balances?: EntityPerTree<Balance> | null,
	transactionType: TransactionType,

	token: string,

	onCancel: () => void,
	onClose: () => void,
	setCorrespondentTree: (treeId: number) => void,

	loadBalanceAttributes: (treeId: number, balanceId: number, token: string) => void,
	loadBalanceAttributeTypes: (token: string) => void,
	loadTrees: (token: string) => void,

	createTransaction: (payload: payloads.CreateTransactionPayloadType, token: string) => void
}

const TRANSACTION_CUSTOM_DATE = 'customDate';
const TRANSACTION_AMOUNT = 'amount';
const TRANSACTION_EXTERNAL_AMOUNT = 'externalBalance'
const TRANSACTION_CONVERSION_RATE = 'conversionRate'
const TRANSACTION_COMMENT = 'comment';
const TRANSACTION_BALANCE_ATTRIBUTES = 'balance-attributes';
const TRANSACTION_SOURCE = 'source';
const TRANSACTION_TARGET = 'target';

type AttributeValuesType = {
	[attributeId: number]: {
		id: number
	} | {
		value: string
	} | undefined
}

type EntryAttributesType = {
	[attributeIdStr: string]: string | undefined
}

type EntryType = {
	[TRANSACTION_BALANCE_ATTRIBUTES]: EntryAttributesType
}

interface TransactionProperties{
	[TRANSACTION_CUSTOM_DATE]: moment.Moment,
	[TRANSACTION_AMOUNT]: number,
	[TRANSACTION_EXTERNAL_AMOUNT]: number,
	[TRANSACTION_CONVERSION_RATE]: number,
	[TRANSACTION_COMMENT]: string,
	[TRANSACTION_SOURCE]: EntryType,
	[TRANSACTION_TARGET]: EntryType,
}

const containerClassName = 'transaction-wizard';

class TransactionWizard extends React.Component<TransactionWizardProps, {conversionChecked: boolean, convTarget: Record<string, boolean>, balanceType: BalanceTypeSlug, precision: Record<BalanceTypeSlug, number>}>{
	formRef = React.createRef<FormInstance>();
	constructor(props: TransactionWizardProps) {
		super(props);
		this.state = {conversionChecked: false, convTarget: {
				amount: false,
				externalAmount: false,
				rate: false
			}, balanceType: 'natural', precision: {
				natural: 0,
				finance: 2,
				calculate: 2
			}};
	}
	componentDidMount() {
		this.loadData();

		const {source, balanceTypes, balances} = this.props
		let balanceTypeId = balances && balances[source.treeId]?.list?.find(treebalance => treebalance.id === source.balanceId)?.typeId;
		let balanceTypeSlug = balanceTypes?.find(balance => balance.id === balanceTypeId)?.slug;
		this.setState({...this.state, balanceType: (balanceTypeSlug as BalanceTypeSlug) || 'natural'});


		this.fetchBalanceSettings();
	}

	componentDidUpdate(){
		this.loadData();
	}

	fetchBalanceSettings() {
		const {token} = this.props
		const url = `${config.api_url}/settings/balances`;
		const headers = new Headers([
			['content-type', 'application/json'],
			["Authorization", `Bearer ${token}`]
		]);
		const responseMock = {
			"data": {
				"balances": {
					"natural": {
						"leftOfPointLength": 11,
						"rightOfPointLength": 0
					},
					"finance": {
						"leftOfPointLength": 10,
						"rightOfPointLength": 4
					},
					"calculate": {
						"rightOfPointLength": 4
					}
				}
			}
		}
		fetch(url, {
			method: 'GET',
			headers,
		}).then(response => {
			return response.json();
		}).then(response => {
			if(!response.data) {
				response = responseMock
			}
			this.setState({
				...this.state,
				precision: {
					...this.state.precision,
					natural: response.data.balances.natural?.rightOfPointLength,
					finance: response.data.balances.finance?.rightOfPointLength,
					calculate: response.data.balances.calculate?.rightOfPointLength,
				}
			})
		})
	}

	loadData = () => {
		const {
			source, target,
			balanceAttributes, loadBalanceAttributes,
			balanceAttributeTypes, loadBalanceAttributeTypes,
			trees, isTreesLoading, loadTrees,
			token
		} = this.props;

		const sourceAttributes = balanceAttributes[source.balanceId];
		if(!sourceAttributes?.list && !sourceAttributes?.isLoading){
			loadBalanceAttributes(source.treeId, source.balanceId, token);
		}

		if(target && target.treeId && target.balanceId){
			const targetAttributes = balanceAttributes[target.balanceId];
			if(!targetAttributes?.list && !targetAttributes?.isLoading){
				loadBalanceAttributes(target.treeId, target.balanceId, token);
			}
		}

		if(!balanceAttributeTypes.list && !balanceAttributeTypes.isLoading){
			loadBalanceAttributeTypes(token)
		}

		if(!trees && !isTreesLoading){
			loadTrees(token);
		}
	}

	amountValidator(_: any, value: any) {
		if(value == null || value === ''){
			return Promise.reject(new Error(errors.fields_is_required));
		}
		// allowed only not more than 4 decimal places for fractional part
		const string = `${value}`;
		const dotIndex = string.indexOf('.');
		if((dotIndex > 0) && (string.length - dotIndex > this.state.precision.finance + 2)){
			return Promise.reject(new Error(errors.field_is_incorrect));
		}
		if(value > 0){
			return Promise.resolve();
		}
		return Promise.reject(new Error(errors.field_is_incorrect));
	}

	close = () => {
		const {onClose, onCancel} = this.props;
		onCancel();
		onClose();
	}

	cancel = () => {
		const {onCancel} = this.props;
		onCancel();
	}

	extractAttributeValues = (
		entry?: EntryType,
		balanceAttributes?: BalanceAttribute[] | null,
		balanceAttributeTypes?: BalanceAttributeType[] | null
	): AttributeValuesType => {
		const result: AttributeValuesType = {}
		if(!entry){
			return result;
		}
		Object.entries(entry[TRANSACTION_BALANCE_ATTRIBUTES]).reduce((acc, [balanceAttributeIdStr, value]) => {
			const attrId = Number(balanceAttributeIdStr);
			const attribute = balanceAttributes?.find(a => a.id === attrId);
			const attributeTypeSlug = balanceAttributeTypes?.find(bat => bat.id === attribute?.typeId)?.slug;
			switch(attributeTypeSlug){
				case slugs.balanceAttribute_type.text:
				case slugs.balanceAttribute_type.grouped_text:
					acc[attrId] = {value: value || ''};
					break;
				case slugs.balanceAttribute_type.radio:
				case slugs.balanceAttribute_type.select:
					const id = Number(value)
					if(!isNaN(id)){
						acc[attrId] = {id}
					}
			}
			return acc
		}, result)
		return result;
	}

	createTransaction = (values: TransactionProperties) => {
		const {
			transactionType,
			source, target,
			balanceAttributes, balanceAttributeTypes,
			token,
			createTransaction
		} = this.props;

		const balanceAttributesOfSource = balanceAttributes[source.balanceId]?.list;

		const sourceAttributes = this.extractAttributeValues(
			values[TRANSACTION_SOURCE],
			balanceAttributesOfSource,
			balanceAttributeTypes?.list
		)

		let transactionTarget: payloads.CreateTransactionEntryType | null = null;
		if(target && target.treeId && target.node && target.balanceId){
			const balanceAttributesOfTarget = balanceAttributes[target.balanceId]?.list;
			transactionTarget = {
				treeId: target.treeId,
				nodeId: target.node?.id,
				balanceId: target.balanceId,
				attributeValues: this.extractAttributeValues(
					values[TRANSACTION_TARGET],
					balanceAttributesOfTarget,
					balanceAttributeTypes?.list
				)
			}
		}
		const value = Number(values[TRANSACTION_AMOUNT]?.toFixed(this.state.precision[this.state.balanceType]))
		const externalValue = values[TRANSACTION_EXTERNAL_AMOUNT]?.toFixed(this.state.precision[this.state.balanceType]);
		const payload: payloads.CreateTransactionPayloadType = {
			customDate: values[TRANSACTION_CUSTOM_DATE],
			comment: values[TRANSACTION_COMMENT],
			transactionTypeId: transactionType.id,
			value,
			externalValue: externalValue ? Number(externalValue) : undefined,
			source: {
				treeId: source.treeId,
				nodeId: source.node.id,
				balanceId: source.balanceId,
				attributeValues: sourceAttributes
			},
			target: transactionTarget
		}
		createTransaction(payload, token);
		this.cancel();
	}

	renderAttributes = () => {
		const {
			source, target,
			balanceAttributes,
			balanceAttributeTypes
		} = this.props;

		const balanceAttributesOfSource = balanceAttributes[source.balanceId];

		if(!balanceAttributesOfSource?.list || !balanceAttributeTypes.list){
			return <Skeleton/>
		}

		const sourceAttributeElements = balanceAttributesOfSource.list
			.sort((a, b) => a.order - b.order)
			.map(attribute => (
				<BalanceAttributeView
					key = {`source-${attribute.id}`}
					className = {`${containerClassName}__balance-attribute`}
					path = {[TRANSACTION_SOURCE, TRANSACTION_BALANCE_ATTRIBUTES]}
					balanceAttribute = {attribute}
					balanceAttributeTypes = {balanceAttributeTypes.list || []}
				/>
			))

		const balanceAttributesOfTarget = (target && target.balanceId && balanceAttributes[target.balanceId]);
		const targetAttributeElements = (balanceAttributesOfTarget ? balanceAttributesOfTarget?.list || [] : [])
				.sort((a, b) => a.order - b.order)
				.map(attribute => (
					<BalanceAttributeView
						key = {`target-${attribute.id}`}
						className = {`${containerClassName}__balance-attribute`}
						path = {[TRANSACTION_TARGET, TRANSACTION_BALANCE_ATTRIBUTES]}
						balanceAttribute = {attribute}
						balanceAttributeTypes = {balanceAttributeTypes.list || []}
					/>
				));

		const attributesInfo = () => {
			const {nodesInfo, node, source} = this.props;
			const nodeAttributes = (node && nodesInfo[node.id]?.attributes) ?? []
			const foreignNodeAttributes = (node && target?.node?.id && nodesInfo[target?.node?.id]?.attributes) ?? []

			return (
				<Collapse className={`${containerClassName}__body__attributes`}>
					<Collapse.Panel className={`${containerClassName}__body__attributes__attribute`} header={source.node.name} key="-1">
						<RecordsList
							records = {nodeAttributes || []}
							isLoading = {!nodeAttributes}
							getKey = {(attr) => attr.id}
							renderTitle = {(attr) => attr.name}
							renderValue = {(attr) => attr.values.find(v => v.isSelected)?.name}
						/>
					</Collapse.Panel>

					{target && target?.node?.name && (
						<Collapse.Panel className={`${containerClassName}__body__attributes__attribute`} header={target.node.name} key="0">
							<RecordsList
								records = {foreignNodeAttributes || []}
								isLoading = {!foreignNodeAttributes}
								getKey = {(attr) => attr.id}
								renderTitle = {(attr) => attr.name}
								renderValue = {(attr) => attr.values.find(v => v.isSelected)?.name}
							/>
						</Collapse.Panel>
					)}
				</Collapse>

			)
		}

		return (
			<div
			>
				{sourceAttributeElements}
				{balanceAttributesOfTarget && targetAttributeElements}
				Атрибуты узлов
				{attributesInfo()}
			</div>
		)
	}

	renderSingleNode = () => {
		const {
			trees, source, isExpanderShown,
			triggerExpanded
		} = this.props;

		return (
			<div className = {`${containerClassName}__header__nodes`}>
				<NodeInTree
					className = {`${containerClassName}__header__nodes__entries__source`}
					trees = {trees}
					treeId = {source.treeId}
					nodeName = {source.node.name}
				/>
				{isExpanderShown && (
					<div
						className = {`${containerClassName}__header__nodes__expander`}
						onClick = {triggerExpanded}
					>
						<ChevronsDown/>
					</div>
				)}
			</div>
		);
	}

	renderMultipleNodes = () => {
		const {
			trees, source, target,
			isExpanderShown, triggerExpanded,
			setCorrespondentTree
		} = this.props;

		const availableTreeIds: number[] = [];

		const availableTrees = trees
			?.filter(t => availableTreeIds.find(id => t.id === id))

		return (
			<div className = {`${containerClassName}__header__nodes`}>
				<div className = {`${containerClassName}__header__nodes__description`}>
					<span>
						{localization.newTransaction_transfer_description}
					</span>
				</div>
				<div className = {`${containerClassName}__header__nodes__notes`}>
					<span>
						{localization.newTransaction_transfer_notes}
					</span>
				</div>
				<div className = {`${containerClassName}__header__nodes__entries`}>
					<NodeInTree
						className = {`${containerClassName}__header__nodes__entries__source`}
						trees = {availableTrees}
						treeId = {source.treeId}
						nodeName = {source.node.name}
					/>
					<div className = {`${containerClassName}__header__nodes__entries__separator`} />
					<NodeInTree
						className = {`${containerClassName}__header__nodes__entries__target`}
						trees = {trees}
						treeId = {target?.treeId || -1}
						nodeName = {target?.node?.name || null}
						treeIsSelectable
						onTreeChanged = {(treeId) => setCorrespondentTree(treeId)}
					/>
				</div>
				{isExpanderShown && (
					<div
						className = {`${containerClassName}__header__nodes__expander`}
						onClick = {triggerExpanded}
					>
						<ChevronsUp/>
					</div>
				)}
			</div>
		)
	}

	isCustomDateDisabled = (current: Moment): boolean => {
		return current && current > moment().endOf('day');
	}

	renderNodes = () => {
		const {isExpanded} = this.props;
		if(isExpanded){
			return this.renderMultipleNodes();
		}
		return this.renderSingleNode();
	}

	handleFirstSumChange = () => {
		const firstSum = parseFloat(this.formRef.current?.getFieldValue(TRANSACTION_AMOUNT));
		const secondSum = this.formRef.current?.getFieldValue(TRANSACTION_EXTERNAL_AMOUNT);
		const rate = this.formRef.current?.getFieldValue(TRANSACTION_CONVERSION_RATE);

		if (!isNaN(firstSum) && firstSum) {
			this.setState({...this.state, convTarget: {...this.state.convTarget, rate: true}})
			if (secondSum && this.state.convTarget.amount) {
				this.formRef.current?.setFieldsValue({[TRANSACTION_CONVERSION_RATE]: (secondSum / firstSum).toFixed(4)});
			} else if (rate) {
				if (this.state.balanceType === 'natural') {
					this.formRef.current?.setFieldsValue({[TRANSACTION_EXTERNAL_AMOUNT]: Math.round(firstSum * rate)});
				} else {
					this.formRef.current?.setFieldsValue({[TRANSACTION_EXTERNAL_AMOUNT]: (firstSum * rate).toFixed(this.state.precision.finance)});
				}
			}
		} else {
			this.setState({...this.state, convTarget: {...this.state.convTarget, rate: false}})
		}
	}

	handleSecondSumChange = () => {
		const firstSum = this.formRef.current?.getFieldValue(TRANSACTION_AMOUNT);
		const secondSum = parseFloat(this.formRef.current?.getFieldValue(TRANSACTION_EXTERNAL_AMOUNT));
		const rate = this.formRef.current?.getFieldValue(TRANSACTION_CONVERSION_RATE);

		if(!isNaN(secondSum)) {
            this.setState({...this.state, convTarget: {...this.state.convTarget, amount: true}})
            if(firstSum && this.state.convTarget.rate) {
				this.formRef.current?.setFieldsValue({[TRANSACTION_CONVERSION_RATE]: (secondSum / firstSum).toFixed(4)});
			} else if (rate) {
				if (this.state.balanceType === 'natural') {
					this.formRef.current?.setFieldsValue({[TRANSACTION_AMOUNT]: Math.round(secondSum / rate)});
				} else {
					this.formRef.current?.setFieldsValue({[TRANSACTION_AMOUNT]: (secondSum / rate).toFixed(this.state.precision.finance)});
				}
			}
		} else {
            this.setState({...this.state, convTarget: {...this.state.convTarget, amount: false}})
        }

	}

	handleRateChange = () => {
		const firstSum = this.formRef.current?.getFieldValue(TRANSACTION_AMOUNT);
		const secondSum = this.formRef.current?.getFieldValue(TRANSACTION_EXTERNAL_AMOUNT);
		const rate = parseFloat(this.formRef.current?.getFieldValue(TRANSACTION_CONVERSION_RATE));
		if(!isNaN(rate) && rate) {
			if (firstSum && this.state.convTarget.rate) {
				if (this.state.balanceType === 'natural') {
					this.formRef.current?.setFieldsValue({[TRANSACTION_EXTERNAL_AMOUNT]: Math.round(firstSum * rate)});
				} else {
					this.formRef.current?.setFieldsValue({[TRANSACTION_EXTERNAL_AMOUNT]: (firstSum * rate).toFixed(this.state.precision.finance)});
				}
			} else if (secondSum) {
				if (this.state.balanceType === 'natural') {
					this.formRef.current?.setFieldsValue({[TRANSACTION_AMOUNT]: Math.round(secondSum / rate)});
				} else {
					this.formRef.current?.setFieldsValue({[TRANSACTION_AMOUNT]: (secondSum / rate).toFixed(this.state.precision.finance)});
				}
			}
		}
	}

	handleRateBlur = () => {
		const firstSum = this.formRef.current?.getFieldValue(TRANSACTION_AMOUNT);
		if(firstSum) {
			this.setState({...this.state, convTarget:{...this.state.convTarget, rate: true}})
		} else {
			this.setState({...this.state, convTarget:{...this.state.convTarget, rate: false}})
		}
	}

    handleAmountBlur = () => {
        const secondSum = this.formRef.current?.getFieldValue(TRANSACTION_EXTERNAL_AMOUNT);
        if(secondSum) {
            this.setState({...this.state, convTarget:{...this.state.convTarget, amount: true}})
        } else {
            this.setState({...this.state, convTarget:{...this.state.convTarget, amount: false}})
        }
    }

	render(){

		const {
			className,
			target, isExpanded,
			transactionType
		} = this.props;


		let showExecButton;
		if(isExpanded){
			showExecButton = !!(target && target.treeId && target.node && target.balanceId);
		} else {
			showExecButton = true;
		}

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		return (
			<div className = {effectiveClassName}>
				<div className = {`${containerClassName}__header`}>
					<div className = {`${containerClassName}__header__top-menu`}>
						<div
							className = {`${containerClassName}__header__top-menu__back`}
							onClick = {this.cancel}
						>
							<ChevronLeft
								className = "feather-ico"
							/>
							<span>{localization.back}</span>
						</div>
						<div className = {`${containerClassName}__header__top-menu__close`}>
							<X
								className = "feather-ico"
								onClick = {this.close}
							/>
						</div>
					</div>
					<div className = {`${containerClassName}__header__info`}>
						<div className = {`${containerClassName}__header__info__type-name`}>
							<span>{transactionType.name}</span>
						</div>
					</div>
					{this.renderNodes()}
				</div>
				<Form<TransactionProperties>
					className = {`${containerClassName}__body`}
					layout = 'vertical'
					ref={this.formRef}
					initialValues = {{
						[TRANSACTION_CUSTOM_DATE]: moment(),
					}}
					onFinish = {this.createTransaction}
				>
					<div className = {`${containerClassName}__body__content`}>
						<Form.Item
							label = {localization.treeProperties_newTransaction_customDateText}
							name = {TRANSACTION_CUSTOM_DATE}
							rules = {[{
								required: true,
								message: errors.fields_is_required
							}]}
						>
							<DatePicker
								className = {`${containerClassName}__body__content__custom-date`}
								allowClear = {false}
								disabledDate = {this.isCustomDateDisabled}
							/>
						</Form.Item>
						{
							this.props.target?.node && (
								<div className = {`${containerClassName}__conversion__checkbox`}>
									<label>
										<Switch
											checked={this.state.conversionChecked}
											onChange={e => {this.setState({...this.state, conversionChecked: e})}}
										/>
										<span>{` `}{localization.treeProperties_newTransaction_conversionTitle}</span>
									</label>
								</div>
							)
						}
						<Form.Item
							label = {localization.treeProperties_newTransaction_amountText}
							name = {TRANSACTION_AMOUNT}
							rules = {[{
								required: true,
								validator: this.amountValidator.bind(this)
							}]}
						>
							<InputNumber
								className = {`${containerClassName}__body__content__amount`}
								placeholder = {localization.treeProperties_newTransaction_amountPlaceholder}
                                onBlur={this.handleAmountBlur}
								onChange={this.handleFirstSumChange}
								min = {0}
								formatter={value => {
									const str = `${value}`;
									const dotIndex = str.indexOf('.');
									if(dotIndex < 0){
										return str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
									}
									if(str.length - dotIndex > (this.state.balanceType === 'natural' ? 0 : this.state.precision.finance + 1)) {
										return str.slice(0, -1)
									}
									const integerPart = str
										.substring(0, dotIndex)
										.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
									return `${integerPart}${str.substring(dotIndex)}`;
								}}
								parser={value => (value || '').replace(/( *)/g, '')}
							/>
						</Form.Item>
						{
							this.props.target?.node && this.state.conversionChecked && (
								<>
									<Form.Item
										label = {localization.treeProperties_newTransaction_convertedAmountText}
										name = {TRANSACTION_EXTERNAL_AMOUNT}
										rules = {[{
											required: true,
											validator: this.amountValidator.bind(this)
										}]}
									>
										<InputNumber
											className = {`${containerClassName}__body__content__amount`}
											placeholder = {localization.treeProperties_newTransaction_convertedAmountPlaceholder}
											min = {0}
											onBlur = {this.handleRateBlur}
											onChange={this.handleSecondSumChange}
											formatter={value => {
												const str = `${value}`;
												const dotIndex = str.indexOf('.');
												if(dotIndex < 0){
													return str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
												}
												if(str.length - dotIndex > (this.state.balanceType === 'natural' ? 0 : this.state.precision.finance + 1)) {
													return str.slice(0, -1)
												}
												const integerPart = str
													.substring(0, dotIndex)
													.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
												return `${integerPart}${str.substring(dotIndex)}`;
											}}
											parser={value => (value || '').replace(/( *)/g, '')}
										/>
									</Form.Item>
									<Form.Item
										label = {localization.treeProperties_newTransaction_conversionRateText}
										name = {TRANSACTION_CONVERSION_RATE}
									>
										<InputNumber
											className = {`${containerClassName}__body__content__amount`}
											placeholder = {localization.treeProperties_newTransaction_conversionRatePlaceholder}
											min = {0}
											onChange={this.handleRateChange}
											onBlur={this.handleRateBlur}
											formatter={value => {
												const str = `${value}`;
												const dotIndex = str.indexOf('.');
												if(dotIndex < 0){
													return str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
												}
												if(str.length - dotIndex > 5) {
													return str.slice(0, -1)
												}
												const integerPart = str
													.substring(0, dotIndex)
													.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
												return `${integerPart}${str.substring(dotIndex)}`;
											}}
											parser={value => (value || '').replace(/( *)/g, '')}
										/>
									</Form.Item>
								</>
							)
						}

						<Form.Item
							label = {localization.treeProperties_newTransaction_commentText}
							name = {TRANSACTION_COMMENT}
						>
							<TextArea
								showCount
								maxLength = {255}
								className = {`${containerClassName}__body__content__comment`}
								placeholder = {localization.treeProperties_newTransaction_commentPlaceholder}
							/>
						</Form.Item>
						{this.renderAttributes()}
					</div>
					<div className = {`${containerClassName}__body__footer`}>
						{showExecButton && (
							<Form.Item
								className = {`${containerClassName}__body__footer__ok`}
							>
								<Button
									type = 'primary'
									block
								>
									{localization.treeProperties_newTransaction_executeButtonText}
								</Button>
							</Form.Item>
						)}
						<Button
							className = {`${containerClassName}__body__footer__cancel`}
							block
							onClick = {this.cancel}
						>
							{localization.cancel}
						</Button>
					</div>
				</Form>
			</div>
		)
	}
}

export default TransactionWizard;
