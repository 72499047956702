import React from 'react';
import $ from 'jquery';
import { Moment } from 'moment';
import { PivotGrid } from 'devextreme-react';
import { FieldChooser, FieldPanel, StateStoring } from 'devextreme-react/pivot-grid';
import Store from 'devextreme/data/abstract_store';
import CustomStore from 'devextreme/data/custom_store';
import PivotGridDataSource, { PivotGridDataSourceField } from 'devextreme/ui/pivot_grid/data_source';
import dxPivotGrid, { dxPivotGridPivotGridCell } from 'devextreme/ui/pivot_grid';
import { dxElement } from 'devextreme/core/element';

import {Balance, EntityPerTree, report, Report} from '../../models';
import { config, localization } from '../../settings';

import onExport, { ExportOptions } from './export';
import buildFilter from './filter';
import pivotGridPlug from './pivotGridPlug';
import buildSummary from './summary';
import {formatters} from "../../tools";

interface PivotReportTableProps{
	className?: string;
	zoom: number,

	nodeId: number,
	report: Report | null,

	balances: EntityPerTree<Balance>,
	treeId: number,

	token: string,
	updateReportField: (nodeId: number, fieldId: string, update: report.ReportFieldUpdate) => void,
}

const containerClassName = 'pivot-report-table';

const loadData = (nodeId: number, startDate: Moment | null, endDate: Moment | null, token: string) => {
	if(!startDate || !endDate){
		return [];
	}
	const startDateComponent = encodeURIComponent(startDate.format('YYYY-MM-DD'));
	const endDateComponent = encodeURIComponent(endDate.format('YYYY-MM-DD'));
	const url = `${config.api_url}/reports/${nodeId}/data?startDate=${startDateComponent}&endDate=${endDateComponent}&take=20`;
	const headers = new Headers([
		['content-type', 'application/json'],
		["Authorization", `Bearer ${token}`]
	]);
	return fetch(url, {
		headers: headers
	}).then((response) => {
		return response.json();
	}).then((response) => {
		let result = response;
		if('data' in response){
			result = response['data'];
		}
		return result;
	})
}

class PivotReportTable extends React.Component<PivotReportTableProps, {precision: {natural: number, finance: number, calculate: number}}>{
	constructor(props: PivotReportTableProps){
		super(props);

		this.pivotGridRef = null;
		this.dataSource = this.createDataSource();
		this.state = {
			precision: {
				natural: 0,
				finance: 2,
				calculate: 2
			}
		}
	}

	pivotGridRef: PivotGrid | null;
	dataSource: PivotGridDataSource;

	componentDidMount(){
		this.syncDataSource();
	}

	componentDidUpdate(){
		this.syncDataSource();
	}

	componentWillUnmount(){
		this.dataSource.dispose();
	}

	// Expose Export method to the parent component
	export = () => {
		const jComponent = this.pivotGridRef?.instance;
		if(!jComponent){
			return;
		}
		const options: ExportOptions = {
			component: jComponent
		}
		onExport(options)
	}


	createDataSource = (): PivotGridDataSource => {
		const dataSourceStore: Store = new CustomStore({
			loadMode: 'processed',
			load: (loadOptions) => {
				const {nodeId, report, token, balances, treeId} = this.props;

				const headers = new Headers([
					['content-type', 'application/json'],
					["Authorization", `Bearer ${token}`]
				]);
				if(!loadOptions.group){
					return loadData(nodeId, report?.date[0] || null, report?.date[1] || null, token);
				}

				const url = `${config.api_url}/reports/${nodeId}/pivot`;
				let balTypes = loadOptions.groupSummary.map((summaryItem: any) => {
					return balances[treeId]?.list?.find(bal => bal.id === Number(summaryItem.selector.split('.')[1]))?.typeId ?? 1
				})

				const result = $.Deferred();



				const data = {
					filter: buildFilter(loadOptions.filter, pivotGridPlug.revert) || [],
					group: loadOptions.group,
					groupSummary: buildSummary(loadOptions.groupSummary),
					totalSummary: buildSummary(loadOptions.totalSummary),
					nodeId,
					startDate: report?.date[0],
					endDate: report?.date[1],
					dateType: report?.dateType
				}

				fetch(url, {
					method: 'POST',
					headers,
					body: JSON.stringify(data)
				}).then(response => {
					return response.json();
				}).then(response => {
					let data = [];
					let summary = null;
					if('data' in response){
						data = response.data.map((e: any) => pivotGridPlug.convert(e, balTypes, this.state.precision));
					}
					if('totalSummaries' in response){
						summary = response.totalSummaries.map((summ: number, i: number) => {
							if(balTypes[i] === 1) {
								return formatters.formatNumber(summ, this.state.precision.natural)
							} else if (balTypes[i] === 2) {
								return formatters.formatNumber(summ, this.state.precision.finance)
							} else {
								return formatters.formatNumber(summ, this.state.precision.calculate)
							}
						});
					}
					result.resolve(
						data,
						summary != null ? {
							summary: summary
						}: null
					)
				})

				return result;
			}
		})

		return new PivotGridDataSource({
			remoteOperations: true,
			retrieveFields: false,
			store: dataSourceStore,
			fields: [],
			onChanged: () => {
				const {
					nodeId,
					report, updateReportField
				} = this.props;
				const propsFields = (report?.fields || [])
					.map(f => this.getSourceFieldFromReportField(f));
				const pivotFields = this.dataSource.fields();

				const updates = pivotFields
					.filter(pivotField => {
						const propsField = propsFields.find(pf => pf.dataField === pivotField.dataField);
						return !this.areEqualSourceFields(pivotField, propsField)
					})

				if(updates.length > 0){
					updates.forEach(u => {
						if(u.dataField){
							const update: report.ReportFieldUpdate = {
								area: u.area,
								areaIndex: u.areaIndex,
								filterType: u.filterType,
								filterValues: u.filterValues
							};
							updateReportField(nodeId, u.dataField, update);
						}
					})
					this.forceUpdate();
				}
			}
		})
	}

	getSourceFieldFromReportField = (origin: report.Field) => {
		const isMeasure = origin.type === 'balance' || origin.type === 'calculatedBalance';
		let caption = origin.name
		if('relates' in origin){
			switch(origin.relates){
				case 'node':
					caption = `${caption} ${localization.reports_page_node_postfix}`;
					break;
				case 'correspondent':
					caption = `${caption} ${localization.reports_page_correspondent_postfix}`;
					break;
				default: {
					caption = `${caption} (o_o)`;
				}
			}
		}
		const result: PivotGridDataSourceField = {
			dataField: origin.id,
			caption: caption,
			isMeasure: isMeasure,
			summaryType: isMeasure ? 'sum' : undefined,
			// @ts-ignore
			balType: origin.balType ?? undefined,
			customizeText: isMeasure ? undefined : pivotGridPlug.getText,
			sortingMethod: pivotGridPlug.attributesSort,
			area: origin.area,
			areaIndex: origin.areaIndex,
			allowFiltering: true,
			filterType: origin.filterType,
			filterValues: origin.filterValues,
		}
		return result;
	}

	areEqualSourceFields = (a?: PivotGridDataSourceField, b?: PivotGridDataSourceField): boolean => {
		if(!a || !b){
			return !!a === !!b;
		}
		type PropertiesType = "area"| "areaIndex"| "filterType"| "filterValues";
		const properties: Array<PropertiesType> = ["area", "areaIndex", "filterType", "filterValues"];
		for(let i = 0; i < properties.length; i++){
			const propertyName = properties[i];
			if(a[propertyName] !== b[propertyName]){
				return false;
			}
		}
		return true;
	}

	syncDataSource = () => {
		const {report} = this.props;

		const pivotFields = this.dataSource.fields();
		const propsFields = (report?.fields || [])
			.map(f => this.getSourceFieldFromReportField(f));
		const updates = propsFields
			.filter(currentField => {
				const oldField = pivotFields.find(pf => pf.dataField === currentField.dataField);
				return !this.areEqualSourceFields(oldField, currentField)
			})
		if(updates.length > 0){
			const allIds = [
				...pivotFields.map(f => f.dataField),
				...propsFields.map(f => f.dataField)
			].filter((id, index, arr) => arr.indexOf(id) === index)

			const updatedFields = allIds
				.map(id => {
					const foundUpdate = updates.find(u => u.dataField === id);
					if(foundUpdate){
						return foundUpdate;
					}
					return pivotFields.find(f => f.dataField === id);
				}).filter((f): f is PivotGridDataSourceField => !!f)
      
			this.dataSource.fields(updatedFields);
      this.sortFields(this.dataSource);
			this.dataSource.load();

      this.onContentReady();
		}
	}

	onCellPrepared = (e: {
		component?: dxPivotGrid | undefined;
		element?: dxElement | undefined;
		model?: any;
		area?: string | undefined;
		cellElement?: dxElement | undefined;
		cell?: dxPivotGridPivotGridCell | undefined;
		rowIndex?: number | undefined;
		columnIndex?: number | undefined;
	}) => {
		const {cell, area, cellElement} = e;
		if(!cell || !cellElement || !area){
			return;
		}
		if(area === 'data'){
			if((cell.text || '').length > 0){
				const visibleText = cell.text
				cellElement.innerHTML = `<span class='${containerClassName}__data'>${visibleText}</span>`
			}
			if((cell.columnType === 'D') && (cell.rowType === 'D') && (cell.text || '').length < 1){
				cellElement.innerHTML = `<span class='${containerClassName}__data'>0</span>`
			}
		}
	}

	getCustomStyle = (): React.CSSProperties | undefined => {
		const {zoom} = this.props;
		if(zoom >= 2){
			return {
				fontSize: '24px'
			}
		}else if(zoom >= 1.5){
			return {
				fontSize: '20px'
			}
		}else if(zoom >= 1.25){
			return {
				fontSize: '16px'
			}
		}else if(zoom >= 1){
			return {
				fontSize: '14px'
			}
		}else if(zoom >= 0.9){
			return {
				fontSize: '12px'
			}
		}else if(zoom >= 0.75){
			return {
				fontSize: '10px'
			}
		}else if(zoom >= 0.5){
			return {
				fontSize: '6px'
			}
		}
	}

  sortFields = (dataSource: PivotGridDataSource): PivotGridDataSource => {
    const fields = dataSource.fields();
    const sortedFields = fields.map<PivotGridDataSourceField>((field) => {
      if (field.dataField?.includes('month')
        || field.dataField?.includes('year')
        || field.dataField?.includes('day')
      ) {
        return {
          ...field,
          sortingMethod(a: any, b: any) {
            const monthA = Number(a.value?.toString().split('-')[0]);
            const monthB = Number(b.value?.toString().split('-')[0]);

            if (monthA === monthB) {
              return 0;
            }
            return monthA > monthB ? 1 : -1;
          },
        };
      }
      return field;
    });
    dataSource.fields(sortedFields);
    this.dataSource.load();

    return dataSource;
  };

  onContentReady = () => {
    const headerFiltersDivList = document.querySelectorAll('.dx-pivotgrid-fields-area.dx-area-fields')

    const secondActiveHeaderFilterDiv = headerFiltersDivList[1] as HTMLElement;
    const activeHeaderFilterDiv = headerFiltersDivList[3] as HTMLElement;
    const thirdActiveHeaderFilterDiv = headerFiltersDivList[2] as HTMLElement;

    const contentFilterDivList = document.querySelectorAll('.dx-pivotgrid-vertical-headers.dx-pivotgrid-area')

    const activeContentFilterDiv = contentFilterDivList[0] as HTMLElement;

    const contentDivList = document.querySelectorAll('.dx-pivotgrid-horizontal-headers.dx-pivotgrid-area')

    const activeContentDiv = contentDivList[0] as HTMLElement;

    const contentTableDivList = document.querySelectorAll(
      '.dx-pivotgrid-area.dx-pivotgrid-area-data'
    )

    if (activeHeaderFilterDiv) {
      activeHeaderFilterDiv.style.maxWidth = '400px';
      activeHeaderFilterDiv.style.overflowX = 'auto';
      activeHeaderFilterDiv.classList.add('hide-scroll');
      
      activeHeaderFilterDiv.addEventListener(
        'scroll', () => {
          const scrollContainer = activeContentFilterDiv.querySelector('.dx-scrollable-container') as HTMLElement;
          scrollContainer.scrollLeft = activeHeaderFilterDiv.scrollLeft;
        }
      )
    }

    if (secondActiveHeaderFilterDiv) {
      secondActiveHeaderFilterDiv.style.maxWidth = '400px';
      secondActiveHeaderFilterDiv.style.overflowX = 'auto';
      secondActiveHeaderFilterDiv.style.paddingRight = '8px';
    }

    if (thirdActiveHeaderFilterDiv) {
      thirdActiveHeaderFilterDiv.style.width = '100%';
      thirdActiveHeaderFilterDiv.style.overflowX = 'auto';
      thirdActiveHeaderFilterDiv.style.maxWidth = '100%';

    }

    if (activeContentFilterDiv) {
      activeContentFilterDiv.style.maxWidth = '400px';

      const scrollContainer = activeContentFilterDiv.querySelector('.dx-scrollable-container') as HTMLElement;

      if (scrollContainer) {
        scrollContainer.style.overflowX = 'auto';
        scrollContainer.addEventListener(
          'scroll', () => {
            const headerFilterDiv = activeHeaderFilterDiv as HTMLElement;
            headerFilterDiv.scrollLeft = scrollContainer.scrollLeft;
          })
      }
    }

    if (activeContentDiv) {
      activeContentDiv.style.width = '100%';

      const activeContentDivTable = activeContentDiv.querySelector('table') as HTMLElement;

      if (activeContentDivTable) {
        activeContentDivTable.style.width = '100%';
      }
    }
    
    const secondContentTableList = document.querySelectorAll('.dx-pivotgrid-area.dx-pivotgrid-area-data')

    const secondContentTableDiv = secondContentTableList[0] as HTMLElement;
    
    const contentTableDiv = contentTableDivList[0] as HTMLElement;
    if (contentTableDiv) {
      contentTableDiv.style.width = '100%';
      contentTableDiv.style.maxWidth = '100%';

      const table = contentTableDiv.querySelector('table') as HTMLElement;

      if (table) {
        table.style.width = '100%';
        table.style.maxWidth = '100%';
      }
    }

    if (secondContentTableDiv){
      secondContentTableDiv.style.width = '100%';
      secondContentTableDiv.style.maxWidth = '100%';
    }
  
  }

	render(){
		const {
			className,
			nodeId
		} = this.props;

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;
		return (
			<div
				className = {effectiveClassName}
			>
				<PivotGrid
					ref = {(ref) => this.pivotGridRef = ref}
					className = {`${containerClassName}__table`}
					style = {this.getCustomStyle()}
					dataSource = {
            this.sortFields(this.dataSource)
          }
					onCellPrepared = {this.onCellPrepared}
					onExporting = {onExport}
					allowExpandAll
					showBorders
					height = {'100%'}
					texts = {{
						noData: localization.reports_page_table_no_data,
						total: localization.reports_page_table_total,
						grandTotal: localization.reports_page_table_grand_total
					}}
          onContentReady={this.onContentReady}
				>
					<StateStoring
						enabled = {true}
						storageKey = {`pivot-report-store-key-${nodeId}`}
					/>
					<FieldPanel
						visible
						texts = {{
							columnFieldArea: localization.reports_page_table_columns,
							rowFieldArea: localization.reports_page_table_rows,
							dataFieldArea: localization.reports_page_table_data,
							filterFieldArea: localization.reports_page_table_filters
						}}
					/>
					<FieldChooser enabled = {false}/>
				</PivotGrid>
			</div>
		);
	}
}

export default PivotReportTable;
