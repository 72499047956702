import React from 'react';
import { AlertTriangle, Delete, Lock, MoreVertical, Search, Unlock, XCircle } from 'react-feather';
import { Dropdown, Menu, Modal, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment, { Moment } from 'moment';
import dateFormat from 'dateformat';

import {
	AttributeLocator, Transaction, TransactionAttributes, TransactionFilterValueItem, TransactionsTableState,
	TransactionsFilters, TransactionsList, TransactionType, UpdateAttributeLocatorType} from '../../models';
import { Pagination, Filters, Input } from '..';
import { localization, slugs, transactions as transactionSettings, TreeSecurityProvider } from '../../settings';

import OperationsList from './OperationsList';
import { DateInput } from './editables';
import TwoRecordsPlate from './TwoRecordsPlate';
import {formatters} from "../../tools";

export interface TransactionRepresentation extends Transaction{
	key: number
}

const NUMBER_REGEXP = /^\d*$/;
const AMOUNT_REGEXP = /^(-)?\d*$/;

const extractDate = (date?: string | null): Moment | null | undefined => {
	if(date == null){
		return date;
	}
	return moment(date, 'DD-MM-YYYY');
};

interface TransactionsTableProps{
	className?: string,

	treeId: number,
	authorIdFilterValues: TransactionFilterValueItem[] | null,
	nodeIdFilterValues: TransactionFilterValueItem[] | null,
	correspondentIdFilterValues: TransactionFilterValueItem[] | null,
	containerState: TransactionsTableState | null,

	treeSecurityProvider: TreeSecurityProvider,
	token: string,

	transactionTypes: TransactionType[] | null,
	transactions: TransactionsList | null,
	transactionAttributes: TransactionAttributes,
	loadTransactions: (treeId: number, page: number, size: number, filters: TransactionsFilters, token: string) => void,
	loadTransactionAttributes?: (attributes: AttributeLocator[], token: string) => void,
	cancelTransaction?: (treeId: number, transactionId: number, token: string) => void,
	updateTransaction?: (transactionId: number, changes: {comment?: string | null, customDate?: Date}, token: string) => void,
	updateTransactionAttributes?: (attribute: UpdateAttributeLocatorType, token: string) => void,
	saveContainerState: (treeId: number, state: TransactionsTableState) => void,
}

type DropdownSelectOptions = {
	availableValues?: {id: number, text: string}[] | null,
}

const containerClassName = 'bra-transactions-table';

class TransactionsTable extends React.Component<TransactionsTableProps>{
	getData = (): TransactionRepresentation[] | undefined => {
		const {transactions} = this.props;
		return transactions?.list?.map(t => ({
			...t,
			key: t.id,
		}))
	}

	setContainerState = (update: (state: TransactionsTableState | null) => TransactionsTableState) => {
		const {
			treeId,
			containerState, saveContainerState
		} = this.props;
		const updatedState = update(containerState);
		saveContainerState(treeId, updatedState);
	}

	handlePageChange = (pageNumber: number) => {
		const {
			treeId, transactions,
			token,
			loadTransactions
		} = this.props;
		transactions && loadTransactions(treeId, pageNumber, transactions.pageSize, transactions.filters, token);
	}

	getFilterDropdownCommon = (filterName: string, pattern?: RegExp) => (dropdownOptions: FilterDropdownProps) => {
		const {
			treeId, token,
			transactions, loadTransactions
		} = this.props;

		const {
			setSelectedKeys, confirm, clearFilters
		} = dropdownOptions;

		const value = (transactions?.filters || {})[filterName];
		const onFilterChange = (value: string) => {
			if(!transactions){
				return;
			}
			if(value.length < 1){
				return onFilterReset();
			}
			setSelectedKeys([value]);

			const {pageSize, filters} = transactions;
			const updatedFilters = {
				...filters,
				[filterName]: value
			}
			loadTransactions(treeId, transactionSettings.defaultFirstPage, pageSize, updatedFilters, token);

			confirm();
		}
		const onFilterReset = () => {
			if(!transactions){
				return;
			}
			const {pageSize, filters} = transactions;
			const updatedFilters = {
				...filters,
			}
			delete updatedFilters[filterName];
			loadTransactions(treeId, transactionSettings.defaultFirstPage, pageSize, updatedFilters, token);

			clearFilters && clearFilters();
		}
		return (
			<Filters.SubstringFilter
				pattern = {pattern}
				value = {value}
				onChange = {onFilterChange}
				onReset = {onFilterReset}
			/>
		)
	}

	getDropDownDateRange = (filterNameFrom: string, filterNameTo: string) => (dropdownOptions: FilterDropdownProps) => {
		const {
			treeId, token,
			transactions, loadTransactions
		} = this.props;

		const {
			setSelectedKeys, confirm, clearFilters
		} = dropdownOptions;

		const onFilterChange = (startDate: Moment | null, endDate: Moment | null) => {
			if(!transactions){
				return;
			}
			if(!startDate && !endDate){
				return onFilterReset();
			}
			// As type of key doos not accept null or undefined, store null value as string
			setSelectedKeys([startDate?.valueOf() || 'NULL', endDate?.valueOf() || 'NULL']);

			const {pageSize, filters} = transactions;
			const updatedFilters = {
				...filters
			}
			if(startDate != null){
				updatedFilters[filterNameFrom] = startDate.format('DD-MM-YYYY');
			}
			if(endDate != null){
				updatedFilters[filterNameTo] = endDate.format('DD-MM-YYYY');
			}
			loadTransactions(treeId, transactionSettings.defaultFirstPage, pageSize, updatedFilters, token);

			confirm();
		}
		const onFilterReset = () => {
			if(!transactions){
				return;
			}
			const {pageSize, filters} = transactions;
			const updatedFilters = {
				...filters,
			}
			delete updatedFilters[filterNameFrom];
			delete updatedFilters[filterNameTo];
			loadTransactions(treeId, transactionSettings.defaultFirstPage, pageSize, updatedFilters, token);

			clearFilters && clearFilters();
		}
		const startDate = extractDate((transactions?.filters || {})[filterNameFrom]);
		const endDate = extractDate((transactions?.filters || {})[filterNameTo]);
		return (
			<Filters.RangeFilter
				startDate = {startDate}
				endDate = {endDate}
				onChange = {onFilterChange}
				onReset = {onFilterReset}
			/>
		)
	}

	getDropdownSelect = (filterName: string, options: DropdownSelectOptions) => (dropdownOptions: FilterDropdownProps) => {
		const {
			treeId, token,
			transactions, loadTransactions
		} = this.props;

		const {
			setSelectedKeys, confirm, clearFilters
		} = dropdownOptions;

		const onFilterChange = (value: number) => {
			if(!transactions){
				return;
			} 

			const {pageSize, filters} = transactions;
			const updatedFilters = {
				...filters
			}
			updatedFilters[filterName] = `${value}`;

			setSelectedKeys([value]);
			loadTransactions(treeId, transactionSettings.defaultFirstPage, pageSize, updatedFilters, token);

			confirm();
		}

		const onFilterReset = () => {
			if(!transactions){
				return;
			}
			const {pageSize, filters} = transactions;
			const updatedFilters = {
				...filters,
			}
			delete updatedFilters[filterName];
			setSelectedKeys([]);
			loadTransactions(treeId, transactionSettings.defaultFirstPage, pageSize, updatedFilters, token);

			clearFilters && clearFilters();
		}

		const value = (transactions?.filters || {})[filterName];
		const valueId = value ? parseInt(value) : null;
		return (
			<Filters.SelectFilter
				valueId = {valueId}
				availableValues = {options.availableValues || null}
				onChange = {onFilterChange}
				onReset = {onFilterReset}
			/>
		)
	}

	toggleEditingTransaction = (transactionId: number) => {
		const {treeSecurityProvider, containerState} = this.props;
		const editingTransactionIds = containerState?.editingTransactionIds || [];
		if(!treeSecurityProvider.transactions.canEditOperations && !treeSecurityProvider.transactions.canEditTransactions){
			return;
		}
		if(editingTransactionIds.indexOf(transactionId) > -1){
			const updatedIds = editingTransactionIds
				.filter(id => id !== transactionId);
			return this.setContainerState((state) => ({
				expandedRowIds: [],
				...state,
				editingTransactionIds: updatedIds
			}))
		} else {
			const updatedIds = [...editingTransactionIds, transactionId];
			return this.setContainerState((state) => ({
				expandedRowIds: [],
				...state,
				editingTransactionIds: updatedIds
			}))
		}
	}

	expandRow = (isExpanded: boolean, row: TransactionRepresentation) => {
		const {containerState} = this.props;
		const expandedRowIds = containerState?.expandedRowIds || [];
		const updatedExpandedRowIds = isExpanded ? (
			[...expandedRowIds, row.id]
		) : (
			expandedRowIds.filter(id => id !== row.id)
		);
		return this.setContainerState((state) => ({
			editingTransactionIds: [],
			...state,
			expandedRowIds: updatedExpandedRowIds
		}))
	}

	getFilterValue = (filterName: string) => {
		const {transactions} = this.props;
		const filters = transactions?.filters || {};
		return filters[filterName];
	}

	onDeleteTransaction = (id: number) => {
		const {
			treeId,
			token,
			cancelTransaction
		} = this.props;
		return Modal.confirm({
				title: localization.cancelTransaction_title,
				icon: <Delete style = {{float: 'left', marginRight: 10}} />,
				content: localization.cancelTransaction_content,
				okText: localization.yes,
				okType: 'danger',
				cancelText: localization.no,
				onOk() {
					cancelTransaction && cancelTransaction(treeId, id, token);
				}
			});
	}

	getColumns = (): ColumnType<TransactionRepresentation>[] => {
		const {
			transactionTypes,
			authorIdFilterValues,
			nodeIdFilterValues,
			correspondentIdFilterValues,
			transactions
		} = this.props;

		const availableTransactionTypes = transactionTypes?.map(t => ({
			id: t.id,
			text: t.name
		})) || [];

		const getSearchIconElement = (fieldName: string | string[]) => {
			let isValueSelected;
			if(Array.isArray(fieldName)){
				isValueSelected = fieldName.reduce((acc, cur) => acc || this.getFilterValue(cur) != null, false)
			}else{
				isValueSelected = this.getFilterValue(fieldName) != null
			}
			const filterClassName = isValueSelected
				? `${containerClassName}__filter ${containerClassName}__filter--highlighted`
				: `${containerClassName}__filter`
			return (
				<div className = {filterClassName}>
					<Search className = 'feather-ico'/>
				</div>
			)
		}
		
		return [{
			title: localization.operations_page_transaction_number,
			dataIndex: 'id',
			filtered: !!this.getFilterValue('transactionId'),
			filterIcon: getSearchIconElement('transactionId'),
			filterDropdown: this.getFilterDropdownCommon('transactionId', NUMBER_REGEXP),
			width: 160,
			render: (value: any, record: TransactionRepresentation) => {
				let prefixElement = null;
				switch(record.status){
					case slugs.transaction_statuses.canceled:{
						prefixElement = <XCircle className = 'feather-ico'/>
						break;
					}
					case slugs.transaction_statuses.canceling:{
						prefixElement = <AlertTriangle className = 'feather-ico'/>
						break;
					}
				}
				const className = `${containerClassName}__numeric-value`;
				const effectiveClassName = `${className} ${containerClassName}__${record.status}`;
				return (
					<span className = {effectiveClassName}>
						{prefixElement}{value}
					</span>
				);
			}
		},{
			title: localization.operations_page_transaction_type,
			filtered: !!this.getFilterValue('transactionTypeId'),
			dataIndex: 'type',
			filterIcon: getSearchIconElement('transactionTypeId'),
			filterDropdown: this.getDropdownSelect('transactionTypeId', {
				availableValues: availableTransactionTypes
			}),
			width: 120,
			render: (value: TransactionType) => {
				return value.name
			}
		},{
			title: localization.operations_page_transaction_amount,
			filtered: !!this.getFilterValue('value'),
			filterIcon: getSearchIconElement('value'),
			filterDropdown: this.getFilterDropdownCommon('value', AMOUNT_REGEXP),
			width: 160,
			render: (_: any, record: TransactionRepresentation) => {
				let value = [];

				if(record.operations.length > 1 && Math.abs(record.operations[0].value) !== Math.abs(record.operations[1].value)) {
					value.push(formatters.formatNumber(record.operations[0].value), formatters.formatNumber(record.operations[1].value))
				} else {
					value.push(formatters.formatNumber(record.operations[0].value))
				}

				const isNegative = [
					slugs.transaction_types.consumption,
					slugs.transaction_types['write-off']
				].indexOf(record.type.slug) > -1;
				const className = `${containerClassName}__${isNegative ? 'negative' : 'positive'}-value`
				return (
					<span className={`${containerClassName}__numeric-value`}>
						{value.length > 1 ? (
							<>
								<span className={className}>{value[0]}</span>
								<span>	➔ </span>
								<span className={className}>{value[1]}</span>
							</>
						) : (
							<span className={className}>
								{value[0]}
							</span>
						)}
					</span>

				);
			}
		},{
			title: localization.operations_page_transaction_node,
			dataIndex: 'node',
			filtered: !!this.getFilterValue('nodeId'),
			filterIcon: getSearchIconElement('nodeId'),
			filterDropdown: this.getDropdownSelect('nodeId', {
				availableValues: nodeIdFilterValues,
			}),
			width: 200,
			render: (_: any, record: TransactionRepresentation) => {
				const nodeFilter = (transactions?.filters || {})['nodeId'];
				return (
					<TwoRecordsPlate
						filter = {nodeFilter}
						forwardRecord = {record.node || null}
						backwardRecord = {record.correspondent || null}
					/>
				)
			}
		},{
			title: localization.operations_page_transaction_correspondent,
			dataIndex: 'correspondent',
			filtered: !!this.getFilterValue('correspondentId'),
			filterIcon: getSearchIconElement('correspondentId'),
			filterDropdown: this.getDropdownSelect('correspondentId', {
				availableValues: correspondentIdFilterValues,
			}),
			width: 200,
			render: (_: any, record: TransactionRepresentation) => {
				const correspondentFilter = (transactions?.filters || {})['correspondentId'];
				return (
					<TwoRecordsPlate
						filter = {correspondentFilter}
						forwardRecord = {record.correspondent || null}
						backwardRecord = {record.node || null}
					/>
				)

			}
		},{
			title: localization.operations_page_transaction_author,
			dataIndex: 'author',
			filtered: !!this.getFilterValue('authorId'),
			filterIcon: getSearchIconElement('authorId'),
			filterDropdown: this.getDropdownSelect('authorId', {
				availableValues: authorIdFilterValues,
			}),
			render: (value: any) => {
				return value?.name;
			}
		},{
			title: localization.operations_page_transaction_createdAt,
			dataIndex: 'createdAt',
			filtered: !!this.getFilterValue('createdAtFrom') || !!this.getFilterValue('createdAtTo'),
			filterIcon: getSearchIconElement(['createdAtFrom', 'createdAtTo']),
			filterDropdown: this.getDropDownDateRange('createdAtFrom', 'createdAtTo'),
			render: (value: Date) => {
				return dateFormat(value, 'dd-mm-yyyy hh:mm:ss', true);
			}
		},{
			title: localization.operations_page_transaction_customDate,
			dataIndex: 'customDate',
			filtered: !!this.getFilterValue('customDateFrom') || !!this.getFilterValue('customDateTo'),
			filterIcon: getSearchIconElement(['customDateFrom', 'customDateTo']),
			filterDropdown: this.getDropDownDateRange('customDateFrom', 'customDateTo'),
			render: (value: Date, record: TransactionRepresentation) => {
				const {
					token,
					treeSecurityProvider,
					containerState,
					updateTransaction
				} = this.props;
				const editingTransactionIds = containerState?.editingTransactionIds || [];
				const isEditable = editingTransactionIds.indexOf(record.id) > -1;
				return (
					<DateInput
						isEditable = {treeSecurityProvider.transactions.canEditTransactions && isEditable}
						onChange = {customDate => {
							updateTransaction && updateTransaction(record.id, {customDate}, token);
						}}
					>
						{value}
					</DateInput>
				);
			}
		},{
			title: localization.operations_page_transaction_comment,
			dataIndex: 'comment',
			filtered: !!this.getFilterValue('comment'),
			filterIcon: getSearchIconElement('comment'),
			filterDropdown: this.getFilterDropdownCommon('comment'),
			render: (value: string | null | undefined, record: TransactionRepresentation) => {
				const {
					token,
					treeSecurityProvider,
					containerState,
					updateTransaction, cancelTransaction
				} = this.props;
				const editingTransactionIds = containerState?.editingTransactionIds || [];
				const isEditing = editingTransactionIds.indexOf(record.id) > -1;

				let editingElementClassName = `${containerClassName}__editing__toggle--hidden`;
				const isEditable = updateTransaction && (
					treeSecurityProvider.transactions.canEditTransactions || treeSecurityProvider.transactions.canEditOperations
				);
				const isInUsualStatus = record.status == null || record.status === slugs.transaction_statuses.normal;
				const isCancelable = treeSecurityProvider.transactions.canCancel
					&& cancelTransaction
					&& isInUsualStatus;
				let editingIconElement = null;
				if(isEditable){
					if(isEditing){
						editingIconElement = <Unlock className={'feather-ico'}/>;
						editingElementClassName = `${containerClassName}__editing__toggle--highlighted`;
					} else {
						editingIconElement = <Lock className={'feather-ico'}/>;
						editingElementClassName = `${containerClassName}__editing__toggle`;
					}
				}

				return (
					<div className = {`${containerClassName}__editing`}>
						<Input
							className = {`${containerClassName}__editing__content`}
							isEditable = {treeSecurityProvider.transactions.canEditTransactions && isEditing}
							emptyText = {localization.operations_page_transaction_comment_notext}
							onChange = {comment => {
								updateTransaction && updateTransaction(record.id, {comment}, token)
							}}
						>
							{value}
						</Input>
						{isEditable && isInUsualStatus && (
							<div
								className = {editingElementClassName}
								onClick = {() => this.toggleEditingTransaction(record.id)}
							>
								{editingIconElement}
							</div>
						)}
						{isCancelable && (
							<div
								className = {`${containerClassName}__cancel`}
							>
								<Dropdown
									overlay = {(
										<Menu className = {`${containerClassName}__cancel__menu`}>
											<Menu.Item
												className = {`${containerClassName}__cancel__menu__item`}
												icon = {(
													<Delete className = {'feather-ico'}/>
												)}
												onClick = {() => this.onDeleteTransaction(record.id)}
											>
												{localization.cancelTransaction_button_text}
											</Menu.Item>
										</Menu>
									)}
								>
									<MoreVertical className = 'feather-ico' />
								</Dropdown>
							</div>
						)}
					</div>
				);
			}
		}]
	}

	renderExpandedRow = (transaction: TransactionRepresentation) => {
		const {
			treeId,
			transactionAttributes,
			treeSecurityProvider,
			containerState,
			token,
			loadTransactionAttributes, updateTransactionAttributes
		} = this.props;
		const editingTransactionIds = containerState?.editingTransactionIds || [];
		const isEditable = editingTransactionIds.indexOf(transaction.id) > -1;

		return (
			<OperationsList
				numericClassName ={`${containerClassName}__numeric-value`}
				isEditable = {treeSecurityProvider.transactions.canEditOperations && isEditable}
				treeId = {treeId}
				operations = {transaction.operations}
				transactionAttributes = {transactionAttributes}
				token = {token}
				loadTransactionAttributes = {loadTransactionAttributes}
				updateTransactionAttributes = {updateTransactionAttributes}
			/>
		)
	}

	render(){
		const {
			className,
			transactions, containerState
		} = this.props;

		const data = this.getData();
		const columns = this.getColumns();
		const expandedRowKeys = containerState?.expandedRowIds || [];

		const effectiveClassName = className ? `${containerClassName} ${className}` : containerClassName;

		return (
			<div className = {effectiveClassName}>
				<div className = {`${containerClassName}__content`}>
					<Table<TransactionRepresentation>
						dataSource = {data}
						columns = {columns}
						loading = {!data}
						expandable = {{
							expandedRowKeys: expandedRowKeys,
							rowExpandable: () => true,
							expandedRowRender: this.renderExpandedRow,
							onExpand: this.expandRow
						}}
						bordered
						scroll = {{
							x: '100%',
							y: '100%'
						}}
						pagination = {false}
					/>
				</div>
				<div className = {`${containerClassName}__pagination`}>
					{transactions && (
						<Pagination
							pageNumber = {transactions.currentPage}
							onChange = {this.handlePageChange}
						/>
					)}
				</div>
			</div>
		)
	}
}

export default TransactionsTable;
